<template src="./registerOfDependents.html"></template>

<script>
import mixins from "../../../mixins/mixins.js";
export default {
  mixins: [mixins],
  data() {
    return {
      dependents: [],
      emailRegistered: null,
      birthday: null,
      convertedBirthday: null,
      cliked: undefined,
      textButton: "Adicionar beneficiários",
      showDependents: false,
      invalidFields: [],
      customerData: {},
      showAlertRegister: false,
      showAlertSignature: false,
      showAlertClick: false,
      genderOptions: [
        { text: "Feminino", value: "F" },
        { text: "Masculino", value: "M" },
      ],
      isRegisteredDependent: false,
      alreadyRegistered: false,
      dependentsCount: 0,
    };
  },
  mounted() {
    const vm = this;
    vm.init();
  },
  methods: {
    init() {
      const vm = this;
      vm.customerData = vm.$session.get("customerData");
      vm.dependentsCount = vm.customerData.customerPlan.additionalLifeMax;
      if (!vm.dependentsCount > 0 ) vm.showAlertSignature = true;
      vm.fillArrays();
      vm.getPatientIdByCpf();
    },
    fillArrays() {
      const vm = this;
      for (let i = 1; i <= vm.dependentsCount; i++) {
        const dependent = { name: "", cpf: "", birthday: "", email: "", phone: "", gender: "", patientCardNumber: "", disabledFields: false };
        const invalid = { name: false, cpf: false, birthday: false, email: false, phone: false, gender: false, patientCardNumber: false };
        vm.dependents.push(dependent);
        vm.invalidFields.push(invalid);
      }
      if (vm.testNullUndefinedEmpty(vm.dependents[0].id)) {
        vm.disabledFields = false;
        vm.showAlert = true;
      }
    },
    verifyStatus() {
      const vm = this;
      if (vm.showAlertSignature) return;
      vm.addOrEditDependents();
    },
    addOrEditDependents() {
      const vm = this;
      vm.cliked = true;
      vm.showDependents = true;
    },
    clickCancel() {
      const vm = this;
      vm.cliked = false;
      vm.showDependents = false;
    },
    getDependents() {
      const vm = this;
      if (!vm.dependentsCount) return;
      vm.showAlertRegister = true;
      vm.showAlertSignature = false;
      vm.showAlertClick = false;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "company/patient-dependents/" +
            vm.customerData.idPatient,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function(response) {
          if (response.status == 200) {
            if (response.data.length > 0) {
              const depentends = response.data;
              for(const d in depentends){
                vm.dependents[d].id = depentends[d].id;
                vm.dependents[d].name = depentends[d].name;
                vm.dependents[d].cpf = depentends[d].cpf;
                vm.dependents[d].birthday = depentends[d].birthday;
                vm.dependents[d].gender = depentends[d].gender;
                vm.dependents[d].phone = vm.maskCelPhone(depentends[d].phone);
                vm.dependents[d].email = depentends[d].email;
                vm.dependents[d].patientCardNumber = depentends[d].patientCardNumber;
                vm.dependents[d].disabledFields = true;
              }
              vm.showAlertRegister = false;
              vm.showAlertSignature = false;
              vm.showAlertClick = true;
              vm.textButton = "Gerenciar beneficiários";
            }
            vm.customerData.additionalLife = response.data.length;
          }
        })
        .catch(function() {
          for(const dep in vm.dependents){
            vm.dependents[dep].disabledFields = false;
          }
          vm.showAlertRegister = true;
          vm.showAlertSignature = false;
          vm.showAlertClick = false;
        });
    },
    validateSaveDependent(indexCard) {
      const vm = this;
      let valid = true;
      // console.log("VM => ", vm.dependents[indexCard]);
      if (vm.testNullUndefinedEmpty(vm.dependents[indexCard].name)) {
        vm.invalidFields[indexCard].name = true;
        valid = false;
      } else if (vm.testFullName(vm.dependents[indexCard].name) === false) {
        vm.invalidFields[indexCard].name = true;
        valid = false;
      }
      if (vm.validateCPF(vm.dependents[indexCard].cpf) === false) {
        console.log("CPF => ", vm.dependents[indexCard].cpf)
        vm.invalidFields[indexCard].cpf = true;
        valid = false;
      }
      // if (vm.validateMinority(vm.dependents[indexCard].birthday) === false) {
      //   console.log("BDay => ", vm.dependents[indexCard].birthday)
      //   vm.invalidFields[indexCard].birthday = true;
      //   valid = false;
      // }
      if (vm.testNullUndefinedEmpty(vm.dependents[indexCard].phone)) {
        vm.invalidFields[indexCard].phone = true;
        valid = false;
      }
      if (
        vm.testNullUndefinedEmpty(vm.dependents[indexCard].phone) === false &&
        vm.dependents[indexCard].phone.length < 15
      ) {
        vm.invalidFields[indexCard].phone = true;
        valid = false;
      }
      if (vm.testNullUndefinedEmpty(vm.dependents[indexCard].gender)) {
        vm.invalidFields[indexCard].gender = true;
        valid = false;
      }
      if(vm.testNullUndefinedEmpty(vm.dependents[indexCard].id) && vm.alreadyRegistered === true){
        vm.invalidFields[indexCard].cpf = true;
        valid = false;
      }
      if(vm.testNullUndefinedEmpty(vm.dependents[indexCard].email)){
        vm.invalidFields[indexCard].email = true;
        valid = false;
      }
      if (valid === true) {
        vm.saveDependent(indexCard);
      } else {
        vm.$vs.notify({
          title: "Atenção!",
          time: 5000,
          text:
            "Campos obrigatórios não preenchidos ou inválidos! Verifique os dados digitados no " +
            (indexCard + 1) +
            "º  beneficiário e tente novamente.",
          color: "warning",
          icon: "error",
          position: "top-right",
        });
      }
    },
    saveDependent(indexCard) {
      const vm = this;
      vm.isRegisteredDependent = false;
      const cpf = vm.dependents[indexCard].cpf.replace(/\D+/g, "");
      const phone = vm.dependents[indexCard].phone.replace(/\D+/g, "");

      if (vm.dependents[indexCard].birthday.includes(".")) {
        vm.dependents[indexCard].birthday = vm.dependents[indexCard].birthday.replace(/\./g, "/");
      }

      const dateParts = vm.dependents[indexCard].birthday.split("/");
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
      const initialDate = new Date(`${year}-${month}-${day}`);

      if (!isNaN(initialDate.getTime())) {
        vm.birthday = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      }

      const dependent = {
        name: vm.dependents[indexCard].name,
        cpf: cpf,
        birthday: vm.birthday,
        gender: vm.dependents[indexCard].gender,
        phone: phone,
        rg: null,
        email: vm.dependents[indexCard].email,
        b2b: false,
        address: {
          zipcode: vm.customerData.address.zipcode,
          street: vm.customerData.address.street,
          number: vm.customerData.address.number,
          city: vm.customerData.address.city,
          complement: vm.customerData.address.complement,
          neighborhood: vm.customerData.address.neighborhood,
          state: vm.customerData.address.state,
        },
      };
      if (vm.testNullUndefinedEmpty(vm.dependents[indexCard].id) == false) {
        vm.isRegisteredDependent = true;
        dependent.id = vm.dependents[indexCard].id;
      }
      vm.$vs.loading();
      vm.axios
        .post(
          vm.$store.state.filooServer +
            "company/dependent/" +
            vm.customerData.signatureId +
            "/" +
            vm.customerData.companyId,
          dependent,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function(response) {
          if (response.status == 200) {
            vm.dependents[indexCard] = response.data;
            if (vm.isRegisteredDependent == true)
              vm.showNotifySuccess(
                "Sucesso",
                `Os dados do beneficiário ${vm.dependents[indexCard].name} foram alterados com sucesso!`,
                4000
              );
            else
              vm.showNotifySuccess(
                "Sucesso",
                `O beneficiário ${vm.dependents[indexCard].name} foi adicionado com sucesso!`,
                4000
              );
            vm.getDependents();
          }
        })
        .catch(() => {
          vm.emailRegistered ? vm.showNotifyWarning(
            "Email já cadastrado.",
            3200
          ) :
            vm.showNotifyWarning(
              "Não foi possível salvar os dados do beneficiário, confira os dados e tente novamente!",
              4000
            );
        }).finally(() => {
          vm.$vs.loading.close();
        });
      // vm.openLoading();
    },
    getPatientIdByCpf() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "company/cpfEmployees/" +
            vm.customerData.cpf,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            vm.customerData.idPatient = response.data;
            vm.getDependents();
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    checkRegisteredCustomer(indexDependents) {
      const vm = this;
      vm.alreadyRegistered = false;
      if (
        vm.testNullUndefinedEmpty(vm.dependents[indexDependents].cpf) === false
      ) {
        const cpf = vm.dependents[indexDependents].cpf.replace(/\D+/g, "");
        vm.axios
          .get(vm.$store.state.filooServer + "customerplan/verifyCpf/" + cpf)
          .then(function(response) {
            if (vm.testNullUndefinedEmpty(response.data.id) == false) {
              vm.alreadyRegistered = true;
              vm.showNotifyWarning(
                "Este CPF já consta na nossa base de dados! Para mais informações entre em contato com a equipe de atendimento através do e-mail: contato@filoo.com.br.",
                6000
              );
            }
          })
          .catch(function(error) {
            console.error(error);
          });
      }
    },
    checkRegisterEmail (indexDependents) {
      const vm = this;
      vm.emailRegistered = false;
      if(!this.dependents[indexDependents].email) {
        this.alertMessage.email = "Campo obrigatório"
      } else if (!this.validateEmail(this.dependents[indexDependents].email)) {
        this.alertMessage.email = "Insira um E-mail válido"
      } else {
        this.axios.post(
          `${this.$store.state.filooServer}customerplan/verifyEmail`,
          { email: this.dependents[indexDependents].email },
        )
          .then(function(response){
            if (response.data.cpf !== null) {
              vm.emailRegistered = true;
              vm.showNotifyWarning(
                "Email já cadastrado.",
                3200
              )
            }
          })
          .catch(() => {
            this.showNotifyError(
              "Não foi possível realizar a requisição e validar o e-mail inserido",
              6000
            )
          })
      }
    },
  },
};
</script>

<style lang="scss" src="./registerOfDependents.scss">
</style>

